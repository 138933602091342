<template>
  <ejs-dropdownlist
    ref="product"
    v-model="productId"
    :floatLabelType="floatLabelType"
    placeholder="Seleccione producto"
    highlight=true
    :enabled="enabled"
    :dataSource="data"
    :fields="{text: 'searchName', value: 'code'}"
    :query='query'
    allowFiltering=true
    :filtering='filtering'
    :change="onProductChange"
  />
</template>

<script>
import Vue from "vue";
import {DropDownListPlugin} from "@syncfusion/ej2-vue-dropdowns";
import {Query} from '@syncfusion/ej2-data';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

Vue.use(DropDownListPlugin);

const timeout = Vue.$timeout1;

export default {
  name: "ProductSearch",
  props: {
    id: { type: Number, default: 0 },
    enabled: { type: Boolean, default: false },
    floatLabelType: { type: String, default: 'Auto'},
    limit: { type: Number, default: 50 }
  },
  data() {
    return {
      productId: null,
      productText: '',
      productIndex: 0,
      data: [],
      query: new Query().select(['searchName', 'code']).take(this.limit)
    }
  },
  computed: {
    api() {
      return this.$store.state.fastway.parameters.apiURLlegacy;
    }
  },
  watch: {
    id(value) {
      this.reload(value);
    }
  },
  methods: {
    reload(id) {
      const self = this;
      const data = {
        id,
        context: this.$store.state.fastway.userInfo
      }
      this.$http.post(`${this.api}/products/data`, data).then(response => {
        self.data = [];
        if (response.data.success) {
          if (response.data.data) {
            self.data = [response.data.data];
            self.productId = response.data.data.id;
          }
        }
      }).catch(error => {
        let errorMessage = '';
        if (error.message) {
          errorMessage = error.message;
        } else if (error.response) {
          errorMessage = error.response.data.ErrorMessage
        } else {
          errorMessage = 'Error de conectividad'
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al cargar listado de productos',
            text: errorMessage,
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        }, {timeout});
      });
    },
    showPopup() {
      this.$refs.product.showPopup();
    },
    filtering(e) {
      this.data = [];
      const self = this;
      this.$http.post(`${this.api}/v1/products/search`, {phrase: e.text}).then(response => {
        if (response.data.success) {
          if (response.data.data) {
            self.data = [...response.data.data];
          }
          const searchData = JSON.parse(JSON.stringify(self.data));
          if (e.text == '') {
            e.updateData(searchData);
          } else {
            let query = new Query().select(['searchName', 'code', 'barcode', 'name', 'id']);
            query = (e.text !== '') ? query.where('searchName', 'contains', e.text, true, true) : query;
            e.updateData(searchData, query);
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error al cargar listado de productos',
              text: response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'danger'
            }
          }, {timeout});
        }
      }).catch(error => {
        let errorMessage = '';
        if (error.message) {
          errorMessage = error.message;
        } else if (error.response) {
          errorMessage = error.response.data.ErrorMessage
        } else {
          errorMessage = 'Error de conectividad'
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error al cargar listado de productos',
            text: errorMessage,
            icon: 'AlertCircleIcon',
            variant: 'danger'
          }
        }, {timeout});
      });
    },
    onProductChange(args) {
      this.$emit('change', args);
    }
  }
}
</script>

<style scoped>

</style>
